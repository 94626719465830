@tailwind base;
@tailwind components;
@tailwind utilities;
 .PhoneInputCountrySelect{
   width: 70px;
   outline: none;
   background: transparent;
  
}
/* *{
    outline: 1px solid red;
} */
.PhoneInputInput{
    outline: none;
    background: transparent;
}
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  html {
    scroll-behavior: smooth;
  }
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }
  
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }
.PhoneInputCountry {
    display: flex;
}
.PhoneInputCountry img{
    width: 40px;
    height: 30px;
    
    display: none;
}
.stars {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px 10px 20px;
    border-radius: 0 0 0 20px;
    color: #f5b754;
    background: transparent;
}
.stars span {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
.stars i {
    font-size: 10px;
}
.stars .shap-left-top {
    position: absolute;
    top: -2px;
    left: -20px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.testimonials .item .stars .shap-left-top svg {
    width: 24px;
    height: 24px;
}
.testimonials .item .stars .shap-right-bottom {
    position: absolute;
    bottom: -25px;
    right: -2px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.stars .shap-right-bottom svg {
    width: 24px;
    height: 24px;
}